import React, { useState, useEffect, createContext } from "react";
import { getCountries } from "../fetch/countries.fetch";

export const CountriesContext = createContext();

export const CountriesProvider = ({ children }) => {
	const [countries, setCountries] = useState([]);
	const [activeCountry, setActiveCountry] = useState("");
	const [isLoading, setIsLoading] = useState(true);

	const fetchCountries = async () => {
		const data = await getCountries();
		const offsetData = await getCountries(100);

		const mergedData = data.concat(offsetData);

		setCountries(mergedData);
		setIsLoading(false);
	};

	useEffect(() => {
		fetchCountries();
	}, []);

	return (
		<CountriesContext.Provider
			value={{
				isLoading,
				setIsLoading,
				countries,
				fetchCountries,
				activeCountry,
				setActiveCountry,
			}}
		>
			{children}
		</CountriesContext.Provider>
	);
};
