import React, { useState, useEffect } from "react";

const Tabs = ({ children }) => {
	const [tabHeader, setTabHeader] = useState([]);
	const [childContent, setChildContent] = useState({});
	const [active, setActive] = useState("1");

	useEffect(() => {
		const headers = [];
		const content = {};

		React.Children.forEach(children, (child) => {
			const { name, index, children } = child.props;
			headers.push({ name, index });
			content[index] = children;
		});

		setTabHeader(headers);
		setChildContent(content);
	}, [children]);

	const handleActiveTab = (tab) => {
		setActive(tab);
	};

	return (
		<div className="FilterModule__container">
			<div className="FilterModule__heading">
				{tabHeader.map((tab) => (
					<button
						className={`FilterModule__tab ${
							active === tab.index ? "FilterModule__tab--active" : ""
						}`}
						onClick={() => handleActiveTab(tab.index)}
						key={tab.index}
					>
						{tab.name}
					</button>
				))}
			</div>
			<div className="FilterModule__content">
				{Object.keys(childContent).map((key) => {
					if (key === active) {
						return childContent[key];
					}
				})}
			</div>
		</div>
	);
};

export default Tabs;
