import { SITE_URL } from "../constants/fetch.constants";

const LANG = document.getElementById("AlvoContact").getAttribute("data-lang");

export const getEmployeesByDepartment = async (departmentID = 0) => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/employee?department=${departmentID}&lang=${LANG}`
	);
	const response = await request.json();

	return response;
};

export const getEmployeesByVoivodeship = async (voivodeship) => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/employee?voivodeship=${voivodeship}&lang=${LANG}`
	);
	const response = await request.json();

	return response;
};

export const getEmployeesByCountry = async (country) => {
	const request = await fetch(
		`${SITE_URL}/wp-json/wp/v2/employee?country=${country}&lang=${LANG}`
	);

	const response = await request.json();

	return response;
};
