import React, { useContext } from "react";
import { DepartmentContext } from "../data/context/department.context";

export default ({ item }) => {
  const departmentsStore = useContext(DepartmentContext);

  const { activeDepartment, setActiveDepartment } = departmentsStore;

  return (
    <li className="FilterModule__departmentListItem">
      <button
        onClick={() => setActiveDepartment(item.id)}
        className={`${activeDepartment === item.id ? "active" : ""}`}
      >
        {item.name}
      </button>
    </li>
  );
};
