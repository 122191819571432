export default {
	"Działy ALVO": "ALVO Departments",
	"Zespół doradców handlowych": "Sales consultant team",
	"Dystrybutorzy na świecie": "Distributors worldwide",
	"Wyszukaj dystrybutorów za pomocą podanych filtrów po lewej stronie.":
		"Search for distributors using the filters provided on the left.",
	"Nie znalezionych żadnych dystrybutorów.": "No distributors found.",
	"Wybierz województwo": "Choose voivodeship",
	"Wybierz opcje": "Choose option",
	"Wyszukaj pracowników za pomocą podanych filtrów po lewej stronie.":
		"Search for employees using the filters provided on the left.",
	"Nie znalezionych żadnych pracowników.": "No employees found.",
	Dystrybutorzy: "Distributors",
	"Wybierz kraj": "Choose Country",
};
